.button-wrapper .btn {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; /* Add some spacing between the button and other elements */
}

#uploadedAvatar {
    border-radius: 50%;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
}

.account-file-input {
    display: none; /* Hide the file input */
}
.card-top-mt-5{
    margin-top: 150px;
  }
