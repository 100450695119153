/* PageNotFound.css */

.page-not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background: #f7f7f7;
    color: #333;
    padding: 20px;
  }
  
  .error-code {
    font-size: 120px;
    font-weight: bold;
    margin: 0;
    color: #ff4c4c;
    animation: pulse 1.5s infinite;
  }
  
  .error-message {
    font-size: 24px;
    margin-top: 20px;
  }
  
  .back-home-btn {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .back-home-btn:hover {
    background-color: #0056b3;
  }
  
  /* Add a subtle pulse animation to the error code */
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  