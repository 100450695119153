.my-counter span {
    font-size: 35px;
    color: #ffffff;
    font-weight: 600;
}

.my-counter span {
    margin: 0px 0 0 0;
    font-size: 20px;
    color: white;
}
