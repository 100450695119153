.posted-stories {
  background-color: #f7f7f7; /* Light grey background */
  padding: 50px 0;
}

.posted-stories h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
}



.story-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 15px 0px;
  transition: transform 0.3s ease-in-out;
}

.story-card:hover {
  transform: translateY(-10px);
}

.story-card img {
  border-radius: 10px;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.story-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.story-card p {
  color: #666;
  font-size: 1rem;
}
.story-title {
  color: #000 !important;
  font-size: 22px !important;
  margin-bottom: 0px !important;
}
.read-more-toggle {
  background: #3359f5;
  color: #fff;
  padding: 10px 15px;
  border-radius: 30px;
}
.story-card{
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px!important;
padding: 20px 20px 30px!important;
}
.home-contact-area .home-contact-content.event_form{
  left: 0!important;
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Individual page buttons */
.page-item {
  border: 1px solid #ddd;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Active page button */
.page-item.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Hover effect for page buttons */
.page-item:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
